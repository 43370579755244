/* Google fonts */
@import url("https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&family=Inter:wght@100;300;400;500;700;800;900&family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;1,500&display=swap");

/* SETUP */

:root {
  /* Color Styles */
  --primary: #38a454;
  --sencond: #00537b;
  --gray-1: #000000;
  --gray-2: #dedee2;
  --white: #ffffff00;

  /* Font Size Desktop */
  --header-1: 40px;
  --header-2: 34px;
  --header-3: 28px;
  --header-4: 22px;
  --header-5: 20px;
  --header-6: 18px;

  --text-regular: 17px;

  /* Font Weight */
  --font-weight-bold: 700;
  --font-weight-semi-bold: 600;
  --font-weight-medium: 500;
  --font-weight-regular: 400;
  --font-weight-light: 300;
}

body {
  font-family: "Poppins", sans-serif;
  font-display: swap;
}

h1 {
  font-size: var(--header-1);
  line-height: 1.3em;
  padding: 0 0 0 0 !important;
  margin: 0 0 0 0 !important;
  font-weight: var(--font-weight-bold);
}

h2 {
  font-size: var(--header-2);
  line-height: 1.3em;
  padding: 0 0 0 0 !important;
  margin: 0 0 0 0 !important;
  font-weight: var(--font-weight-bold);
}

h3 {
  font-size: var(--header-3);
  line-height: 1.3em;
  padding: 0 0 0 0 !important;
  margin: 0 0 0 0 !important;
  font-weight: var(--font-weight-bold);
}

h4 {
  font-size: var(--header-4);
  line-height: 1.4em;
  padding: 0 0 0 0 !important;
  margin: 0 0 0 0 !important;
}

h5 {
  font-size: var(--header-5);
  line-height: 1.4em;
  padding: 0 0 0 0 !important;
  margin: 0 0 0 0 !important;
}

h6 {
  font-size: var(--header-6);
  line-height: 1.6em;
  padding: 0 0 0 0 !important;
  margin: -5px 0 0 0 !important;
}

p,
ul {
  font-size: var(--text-regular);
  line-height: 1.6em;
  color: var(--gray-1);
  /* padding: 0 0 0 0 !important; */
  margin: 0 0 0 0 !important;
  font-weight: var(--font-weight-medium);
}

.green {
  color: var(--primary);
}

.white {
  color: #fff;
}

.blue {
  color: var(--sencond);
}

.hadingspace {
  margin-top: 30px !important;
}

.pspace {
  margin-top: 20px !important;
}

.handingSpaceBottom {
  margin-bottom: 30px;
}

.landing-services {
  margin-top: 5rem;
}

.landing-services1 {
  margin-top: 12rem;
}

.removeSpace {
  margin: -12px !important;
}

.list {
  background-color: var(--primary);
}

.size {
  margin-top: -11px !important;
}

.text-hover :hover {
  color: var(--primary);
}

.title-box-area {
  color: var(--sencond) !important;
}

.multi-column {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
}

/* button */
.btncontact {
  background-color: var(--primary) !important;
  border: none !important;
  border-radius: 22px !important;
}

.btncontact {
  background-color: transparent !important;
  color: var(--primary) !important;
  border: 2px solid var(--primary) !important;
}

.btncontact1 {
  background-color: #ffffff !important;
  padding: 14px 10px 14px 10px !important;
  color: var(--sencond) !important;
  border-radius: 32px !important;
}

.btncontact1 :hover {
  background-color: var(--sencond) !important;
  color: #fff !important;
}

.btncontact2 {
  background-color: var(--primary) !important;
  padding: 12px 8px 12px 8px !important;
  color: #fff !important;
  border-radius: 32px !important;
  width: 120px;
  font-size: var(--text-regular);
}

.btncontact2:hover {
  background-color: transparent !important;
  color: var(--primary) !important;
  border: 2px solid var(--primary) !important;
}
.btn-hover:hover {
  background-color: var(--sencond) !important;
  color: #fff !important;
}

/* 
.para-black {
  color: #000;
  font-size: var(--text-medium);
  font-weight: var(--font-weight-medium);
  line-height: 31px;
} */

/* background images */
.leftImg {
  background-image: url("../images/Untitled design-1.png");
  max-width: 100%;
  height: auto;
  background-color: var(--primary);
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-services {
  background: linear-gradient(#00000000, #00000000),
    url("../images/home/2.jpeg");
  max-width: 100%;
  height: auto;
  margin-top: -2px;
  background-repeat: no-repeat;
  background-size: cover;
}

.landingClientSays {
  background: linear-gradient(#00000000, #00000000),
    url("../images/home/4.jpeg");
  max-width: 100%;
  height: auto;
  margin-top: -2px;
  background-repeat: no-repeat;
  background-size: cover;
}

.landingAboutUs {
  background: linear-gradient(#00000000, #00000000),
    url("../images/Untitled\ design-2.png");
  max-width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
}

.landingChooseUs {
  background: linear-gradient(#00000000, #00000000),
    url("../images/Untitled\ design-5.png");
  max-width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
}

.landingFAQ {
  background: linear-gradient(#00000000, #00000000),
    url("../images/Untitled\ design-6.png");
  max-width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
}

.landBlog {
  background: linear-gradient(#00000000, #00000000),
    url("../images/Untitled\ design-7.png");
  max-width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
}

.listLanding {
  background: rgb(0, 83, 123);
  background: linear-gradient(
    90deg,
    rgba(0, 83, 123, 1) 35%,
    rgba(255, 255, 255, 1) 100%
  );
}

.footerimg {
  background-color: var(--sencond);
}

/* whatapp icon css */

.whatsapp .wtsapp {
  position: fixed;
  transform: all 0.5s ease;
  background-color: #25d366;
  display: block;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  border-right: none;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  bottom: 70px;
  left: 20px;
  border: 0;
  z-index: 9999;
  width: 50px;
  height: 50px;
  line-height: 50px;
}

.whatsapp .wtsapp:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(50%);
  display: block;
  width: 60px;
  height: 60px;
  background-color: #25d366;
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
}

.whatsapp .wtsapp:focus {
  border: none;
  outline: none;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

.phone {
  display: none;
}

.phone .phne {
  position: fixed;
  transform: all 0.5s ease;
  background-color: #4788c7;
  display: block;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  border-right: none;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  bottom: 150px;
  left: 20px;
  border: 0;
  z-index: 9999;
  width: 50px;
  height: 50px;
  line-height: 50px;
}

.phone .phne:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(50%);
  display: block;
  width: 60px;
  height: 60px;
  background-color: #4788c7;
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
}

.phone .phne:focus {
  border: none;
  outline: none;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

.contact {
  background: linear-gradient(rgba(83, 83, 83, 0.273), rgba(87, 87, 87, 0.273)),
    url("../images/23.png");
  background-position: center;
  background-repeat: no-repeat;
}

.frombg {
  background-color: #ececec;
}

.footer {
  background-color: var(--sencond) !important;
}

/* .box-video{
  padding: 15px;
  width: 100%;
  display: block;
  margin: 40px 0px 0 0px;
  background: #f2f2f2;
  color: #000;
  box-shadow: rgba(0, 0, 0, 0.145) 0px 5px 15px;
} */
.box-video iframe {
  border-radius: 22px;
  padding: 10px;
  width: 100%;
  height: 300px;
  background: #f2f2f2;
  box-shadow: rgba(0, 0, 0, 0.145) 0px 5px 15px;
}

.box-area {
  padding: 15px;

  /* width: 330px; */
  border-radius: 22px;
  position: relative;
  display: block;
  margin: 40px 0px 0 0px;
  background: #f2f2f2;
  color: #000;
  box-shadow: rgba(0, 0, 0, 0.145) 0px 5px 15px;
  /* margin: 40px 0; */
}

.box-area .content {
  color: #262626;
}

.box-area .img-area {
  width: 90px;
  height: 90px;
  position: absolute;
  top: -40px;
  left: 0;
  bottom: 0;
  margin: 0 auto;
  right: 0;
  z-index: 1;
  border: 5px solid #fff;
  border-radius: 50%;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.5);
}

.img-area1 img {
  height: 20rem;
  width: 100%;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.box-area .img-area img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

@media only screen and (max-width: 991px) {
  .owl-nav {
    display: none;
  }

  .phone {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .box-area {
    text-align: center;
  }

  .owl-nav {
    display: none;
  }

  .phone {
    display: block;
  }
}

@media (max-width: 1025px) {
  .landing-services {
    padding-top: 60px;
  }
}
@media (max-width: 976px) {
  .landing-services {
    padding-top: 20px;
  }

  .multi-column {
    grid-template-columns: 1fr;
  }

  .phone {
    display: block;
  }

  .leftImg {
    background-image: url("../images/home/bg-8.webp");
    max-width: 100%;
    height: auto;
    background-color: var(--primary);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .hero-services {
    background: linear-gradient(#00000000, #00000000),
      url("../images/home/bg-7.webp");
    max-width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .landingAboutUs {
    background: linear-gradient(#00000000, #00000000),
      url("../images/home/bg-7.webp");
    max-width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .box-video video {
    width: 350px;
    height: 200px;
  }

  .landing-services1 {
    margin-top: 8rem;
  }
}

/* MOBILE VERSION ONLY */

@media (max-width: 576px) {
  .multi-column {
    grid-template-columns: 1fr;
  }

  .phone {
    display: block;
  }

  .landingClientSays {
    height: auto;
  }

  .navbar {
    padding: 0px 0;
  }

  a.nav-link {
    margin-right: 7px 0;
  }

  section.hero img {
    margin: 2rem 0;
  }

  .navbar {
    padding: 0px 0;
  }

  a.nav-link {
    margin-right: 7px 0;
  }

  section.hero {
    text-align: center;
  }

  .btn {
    display: block;
    margin: 1rem 0;
  }

  .ms-3 {
    margin-left: 0 !important;
  }

  .landing-services {
    margin-top: 4px;
  }

  .landing-services1 {
    margin-top: 20px;
  }

  .leftImg {
    background-image: url("../images/home/bg-8.webp");
    max-width: 100%;
    height: auto;
    background-color: var(--primary);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .hero-services {
    background: linear-gradient(#00000000, #00000000),
      url("../images/home/bg-7.webp");
    max-width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .landingAboutUs {
    background: linear-gradient(#00000000, #00000000),
      url("../images/home/bk.jpeg");
    max-width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .box-video video {
    width: 100%;
    height: 160px;
  }
}
