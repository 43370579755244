.dropdown {
  position: relative;

}
.dropdown button{
  cursor: pointer;
  color: var(--sencond);
  border: none;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-size: var(--header-4);
  font-weight: 700;
  background-color: transparent;
}
.navbar-light .navbar-nav .nav-link {
  color: var(--sencond);
}

.menu {
  position: absolute;
  color: var(--sencond);
  border: none;
  list-style-type: none;
  /* margin: 5px 0; */
  cursor: pointer;
  padding: 0;
  width: 200px;
}

.menu > li {
  margin: 0;
  background-color: white;
  color: var(--sencond) !important;
  padding: 10px 0 10px 0;
  border-bottom: 1px solid #f7f7f7;
}

.menu > li:hover {
  background-color: rgb(239, 239, 239);
}

.menu > li > a {
  width: 100%;
  height: 100%;
  color: var(--sencond);
}


.navbar1 {
  background: transparent;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.active {
  background: #fff !important;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 80px; */
  max-width: 1500px;
}

.navbar-logo {
  color: var(--sencond);
  justify-self: start;
  margin-left: 15px;
  cursor: pointer;
  text-decoration: none;
  /* font-size: 2rem; */
  display: flex;
  background-position: center;
  background-size: cover;
  /* font-size: var(--header-3); */
  /* font-weight: 700; */
  align-items: center;

}


.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 80vw;
  /* margin-top:1%; */
  justify-content: end;
  margin-right: 2rem;
}

/* .nav-item {
  height: 60px;
} */

.nav-links {
  color: var(--sencond);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-size: var(--header-4);
  font-weight: 700;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: var(--sencond);
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.navbarBtn {
  background-color: var(--primary);
  border-radius: 22px;
  color: #fff;
  font-size: var(--header-5);
  font-weight: 700;
  margin-bottom: 1.4rem;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 90vh; */
    /* padding-: 15%; */
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 1rem;
    width: 100%;

    display: table;
  }

  .nav-links:hover {
    /* background-color: var(--sencond); */
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    /* transform: translate(25%, 5%); */
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: var(--sencond);
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}

/* .dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  color: var(--sencond);
  align-items: center;
  text-decoration: none;
  padding: 0.8rem 1rem;
  font-size: var(--header-4);
  font-weight: 700;
  height: 100%;
  color: var(--sencond);
  background-color: inherit;
}
.dropdown-content {
  display: none;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.dropdown:hover .dropdown-content {
  display: block;
} */